import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "jquery";
import { AppContextProvider } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <App />
    <ToastContainer />
  </AppContextProvider>
);
