import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import miniLogo from "../../assets/images/logo/mini-logo.png";
import fr from "../../assets/images/navbar/fr.png";
import en from "../../assets/images/navbar/en.png";
import ar from "../../assets/images/navbar/ar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { LANGUAGES } from "../../i18n";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";

const Navbar = () => {
  const { isShowSidebar, setIsShowSidebar } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const pages = ["Home", "Services", "Contacts", "Blogs"];
  const [isActive, setIsActive] = useState(false);

  const showResponsiveNavMenu = () => {
    setIsShowSidebar(!isShowSidebar);
    let sidebar = document.querySelector("#sidebar");
    if (isShowSidebar) {
      sidebar.classList.add("active");
    } else {
      sidebar.classList.remove("active");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavItemClick = (page) => {
    scrollToTop(); // Scroll to top when a nav link is clicked
    // Close sidebar if open
    setIsShowSidebar(false);
    navigate(`/${page.toLowerCase()}`); // Navigate to the clicked page
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar ${isActive ? "active" : ""}`}>
      <Link to="/" className="brand-logo" onClick={scrollToTop}>
        <img src={logo} alt="Logo" />
      </Link>
      <Link to="/" className="mini-brand-logo" onClick={scrollToTop}>
        <img src={miniLogo} alt="Logo" />
      </Link>
      <div className="languages mobile-lang">
        {LANGUAGES.map((lang, index) => (
          <img
            key={index}
            onClick={() => i18n.changeLanguage(lang.code)}
            className="lang-icon"
            src={lang.code === "ar" ? ar : lang.code === "fr" ? fr : en}
            alt={lang.label}
          />
        ))}
      </div>
      <div className={`bar-icon`} onClick={showResponsiveNavMenu}>
        <FontAwesomeIcon
          icon={faBarsStaggered}
          fontSize={40}
          color={"#e84c4f"}
        />
      </div>
      <div className={`nav-items`}>
        {pages.map((page, index) => (
          <Link
            key={index}
            to={page.toLowerCase() === "home" ? "/" : page.toLowerCase()}
            className="nav-item"
            onClick={() => handleNavItemClick(page)}
          >
            {t(`navbar.${page.toLowerCase()}`)}
            {page.toLowerCase().includes("new") && (
              <span style={{ marginLeft: "5px", color: "red" }}>NEW</span>
            )}
          </Link>
        ))}
        <div
          className="buttons"
          onClick={() => {
            navigate("/contacts");
            scrollToTop();
          }}
        >
          <div className="btn-custom">
            <button>{t(`navbar.contacts`)}</button>
            <div className="border"></div>
            <div className="border"></div>
            <div className="border"></div>
            <div className="border"></div>
          </div>
        </div>
        <div className="languages">
          {LANGUAGES.map((lang, index) => (
            <img
              key={index}
              onClick={() => i18n.changeLanguage(lang.code)}
              className="lang-icon"
              src={lang.code === "ar" ? ar : lang.code === "fr" ? fr : en}
              alt={lang.label}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
