import React, { createContext, useState, useEffect } from "react";
import { getAllBlogs } from "../api/functions/posts";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const blogRes = await getAllBlogs();
      if (blogRes.status === 200) {
        setBlogs(blogRes.data.posts);
      }
    };

    fetchData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isShowSidebar,
        setIsShowSidebar,
        blogs,
        setBlogs,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
