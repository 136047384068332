import React, { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Breadcrumb from "../components/Breadcrumb";
import MiniLoader from "../components/Loader/miniLoader";
import { getAllBlogs } from "../api/functions/posts";
import { useTranslation } from "react-i18next";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Blogs = () => {
  const { blogs, currentPage } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getAllBlogs(currentPage);
  }, [currentPage]);

  return (
    <>
      <Breadcrumb page={t(`banner.blogs`)} />
      <section className="blogslist section default-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {blogs.length === 0 ? (
                <MiniLoader />
              ) : (
                blogs.map((blog) => (
                  <div
                    className="card overflow-hidden blog-card"
                    key={blog.id}
                    onClick={() => {
                      navigate(`/blog/${blog.id}`);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link className="cover-image" to={`/blog/${blog.id}`}>
                      <img
                        className="card-image"
                        src={`${IMAGE_URL}/${blog.photo}`}
                        alt=""
                      />
                    </Link>
                    <div className="card-body">
                      <div className="card-main-content">
                        <h4 className="card-title">
                          {i18n.language === "en"
                            ? blog.title.en
                            : i18n.language === "fr"
                            ? blog.title.fr
                            : blog.title.ar}
                        </h4>
                        <div className="d-flex flex-column">
                          <span>
                            {t(`blog.created`)} :{" "}
                            {moment(blog.createdAt).format("Do YYYY")}
                          </span>
                          <span>
                            {t(`blog.updated`)} :{" "}
                            {moment(blog.updatedAt).format("Do YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
