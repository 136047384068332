import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb";

import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Breadcrumb page={t(`banner.contacts`)} />
      <div id="contacts" className="contact default-padding bg-white">
        <div className="container">
          <div className="row">
            <div className="mb-5">
              <h2 className="title text-center">{t(`contact.title`)}</h2>
              <p className="subtitle text-center">{t(`contact.subtitle`)}</p>
            </div>
            <div className="col-md-6" data-aos="fade-right">
              <ul className="icon-list-items mb-4"></ul>
              <ul className="">
                <h3 className="main-heading mb-4">
                  {t(`contact.main-heading`)}
                </h3>
                <p className="subtitle">{t(`contact.sub-heading`)}</p>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <span>livrailiya1@gmail.com</span>
                </li>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <span>0688887905</span>
                </li>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <span>{t(`contact.adress`)}</span>
                </li>
              </ul>
            </div>
            <div className="col-md-6" data-aos="fade-left">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
