import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Breadcrumb = ({ page }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="breadcrumb">
        <div className="container">
          <Link to={"/"} className="text-dark">
            {t(`banner.home`)}
          </Link>
          <span className="slash"></span>
          <Link to={`/`} className="text-dark ps-4">
            {page}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
