import React from "react";
import Banner from "../components/banner";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../components/Breadcrumb";

const Offer = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Breadcrumb page={t(`banner.offer`)} />
      <div>Offer</div>
    </>
  );
};

export default Offer;
