import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const ServiceCard = forwardRef(({ title, subtitle, image }, ref) => {
  const { i18n } = useTranslation();

  return (
    <div className="col-lg-4 col-md-12 mb-4 service-card" ref={ref}>
      <div className="card">
        <img
          src={image}
          className="card-img-top"
          alt="Card Image"
          style={{ borderRadius: "50%" }}
        />
        <div className="card-body">
          <h5 className="card-title">
            {i18n.language === "en"
              ? title.en
              : i18n.language === "ar"
              ? title.ar
              : title.fr}
          </h5>
          <p className="card-text">
            {i18n.language === "en"
              ? subtitle.en
              : i18n.language === "ar"
              ? subtitle.ar
              : subtitle.fr}
          </p>
        </div>
      </div>
    </div>
  );
});

export default ServiceCard;
