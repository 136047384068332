import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/core.scss";

import { useTranslation } from "react-i18next";
import "./i18n/i18n";

import Loading from "./components/Loader";
import Home from "./pages/Home";
import Footer from "./components/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faWhatsapp,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

import AOS from "aos";
import "aos/dist/aos.css";
import Blogs from "./pages/Blogs";
import Ramadan from "./pages/Ramadan";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import SingleBlog from "./pages/SingleBlog";
import PageNotFound from "./pages/PageNotFound";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Offer from "./pages/Offer";
import PhoneIcon from "./components/PhoneIcon";
import WhatsappIcon from "./components/WhatsappIcon";
import Overlay from "./components/common/Overlay";

const App = () => {
  const { i18n } = useTranslation();
  document.dir = i18n.dir();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  const media = [
    {
      icon: <FontAwesomeIcon icon={faInstagram} />,
      link: "https://www.instagram.com/livrailiya1?utm_source=qr&igsh=ZzQwMThyanpvempl",
      class: "insta-bg",
    },
    {
      icon: <FontAwesomeIcon icon={faFacebook} />,
      link: "https://web.facebook.com/profile.php?id=61555356052158&mibextid=ZbWKwL&_rdc=1&_rdr",
      class: "facebook-bg",
    },
    {
      icon: <FontAwesomeIcon icon={faWhatsapp} />,
      link: "https://api.whatsapp.com/send?phone=212688887905",
      class: "whatsapp-bg",
    },
  ];

  const personalInfo = {
    phone: `+212688887905`,
    email: `livrailiya1@gmail.com`,
  };

  return (
    <Router>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <Sidebar />
          <Overlay />

          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/home" element={<Navigate to={"/"} />} />
              <Route
                path="/"
                index
                element={<Home contacts={personalInfo} />}
              />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog/:id" element={<SingleBlog />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contacts" element={<Contact />} />
              <Route path="/offer" element={<Offer />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
          <PhoneIcon phone={personalInfo.phone} />
          <WhatsappIcon phone={media[2].link} />
          <Footer media={media} />
        </>
      )}
    </Router>
  );
};

export default App;
