import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import showToast from "../showToast";
import { sendEmail } from "../../api/functions/sendEmail";

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set the submitting state to true

    try {
      const res = await sendEmail(formData);

      if (res.status === 200) {
        showToast(
          i18n.language === "en"
            ? res.data.message.en
            : i18n.language === "fr"
            ? res.data.message.fr
            : res.data.message.ar,
          "success"
        );
        setIsSubmitting(false); // Reset the submitting state after successful submission
      }
    } catch (error) {
      showToast(error?.response?.data?.message, "error");
      setIsSubmitting(false); // Reset the submitting state if an error occurs
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="fullName" className="form-label">
          {t(`contact.form.labels.name`)}
        </label>
        <input
          type="text"
          className="form-control"
          id="fullName"
          placeholder={t(`contact.form.placeholders.name`)}
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          {t(`contact.form.labels.email`)}
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder={t(`contact.form.placeholders.email`)}
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          {t(`contact.form.labels.phone`)}
        </label>
        <input
          type="tel"
          className="form-control"
          id="phone"
          placeholder={t(`contact.form.placeholders.phone`)}
          value={formData.phone}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="message" className="form-label">
          {t(`contact.form.labels.message`)}
        </label>
        <textarea
          className="form-control"
          id="message"
          rows="3"
          placeholder={t(`contact.form.placeholders.message`)}
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>
      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
        {isSubmitting ? t(`contact.form.sending`) : t(`contact.form.submit`)}
      </button>
    </form>
  );
};

export default ContactForm;
