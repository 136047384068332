import React from "react";
import brandLogo from "../../assets/images/logo/mini-logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ media }) => {
  const { t } = useTranslation();
  const currentYear = 2019;

  return (
    <footer className="default-padding">
      <div className="row">
        <div className="col-md-12">
          <div className="brand-logo mb-3">
            <img src={brandLogo} alt="" />
          </div>
          <p className="mb-3 subtitle">{t(`footer.description`)}</p>
          <div className="d-flex align-items-center justify-content-center gap-3">
            {media &&
              media.map((item, index) => (
                <Link
                  to={item.link}
                  className={`media-icon ${item.class}`}
                  target="_blank"
                  key={index}
                >
                  {item.icon}
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="copyright">
        <a
          href="https://www.lafkiri.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-center"
        >
          All rights reserved © {currentYear} | by Livrai Liya
        </a>
      </div>
    </footer>
  );
};

export default Footer;
