import React, { useEffect } from "react";
import img404 from "../assets/images/404.jpg";
import { Navigate } from "react-router-dom";

const PageNotFound = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      return <Navigate to={"/"} />;
    }, 300);

    // Clear the timeout on component unmount
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div id="PageNotFound">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-5">
          <img src={img404} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
