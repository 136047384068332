import featureBuyer from "../assets/images/features/445e3e4a-dboxc-subject-2.png";
import featureBuyer2 from "../assets/images/features/31a7e1e2-dbox-subject-1.png";
import featureBuyer3 from "../assets/images/features/order.png";

export const featuresData = [
  {
    image: featureBuyer3,
    imageClass: "featurebg2",
    title: {
      en: "Make online shopping experience easy and convenient for your customers with our delivery services.",
      ar: "اجعل تجربة التسوق عبر الإنترنت سهلة ومريحة لعملائك مع خدمات التوصيل الخاصة بنا.",
      fr: "Rendez l'expérience d'achat en ligne facile et confortable pour vos clients avec nos services de livraison.",
    },
    content: {
      en: "- Fast and secure delivery for all orders. - Comprehensive coverage of multiple areas. - Excellent customer service.",
      ar: "- توصيل سريع وآمن لجميع الطلبات. - تغطية شاملة لمناطق متعددة. - خدمة عملاء متميزة.",
      fr: "- Livraison rapide et sécurisée pour toutes les commandes. - Couverture complète de plusieurs zones. - Service clientèle exceptionnel.",
    },
    animation: "-20%",
  },
  {
    image: featureBuyer2,
    imageClass: "featurebg3 position-relative",
    title: {
      en: "Enjoy the convenience of shopping from your home with Livrai-Liya!",
      ar: "استمتع براحة التسوق من منزلك مع Livrai-Liya!",
      fr: "Profitez du confort des achats depuis chez vous avec Livrai-Liya !",
    },
    content: {
      en: "With fast and reliable delivery service, Livrai-Liya offers you an exceptional shopping experience. Order your favorite foods, groceries, and more, and let us bring everything you need to your doorstep. With convenient payment options and a wide range of products, we make your life easier. Discover comfort and quality - choose our service today! 🚚🌟",
      ar: "مع خدمة التوصيل السريعة والموثوقة ، تقدم لك Livrai-Liya تجربة تسوق استثنائية. اطلب أطعمتك المفضلة والمواد الغذائية وأكثر ، واسمحوا لنا بجلب كل ما تحتاجه إلى عتبة بابك. مع خيارات الدفع العملية ومجموعة واسعة من المنتجات ، نجعل حياتك أسهل. اكتشف الراحة والجودة - اختر خدمتنا اليوم! 🚚🌟",
      fr: "Avec un service de livraison rapide et fiable, Livrai-Liya vous offre une expérience d'achat exceptionnelle. Commandez vos plats préférés, vos produits d'épicerie et plus encore, et laissez-nous vous apporter tout ce dont vous avez besoin à votre porte. Avec des options de paiement pratiques et une large gamme de produits, nous simplifions votre vie. Découvrez le confort et la qualité - choisissez notre service aujourd'hui ! 🚚🌟",
    },
    animation: "40%",
  },
  {
    image: featureBuyer,
    imageClass: "position-relative featurebg1",
    title: {
      en: "Combine delivery and marketing for an exceptional experience:",
      ar: "قم بدمج التسليم والتسويق لتجربة استثنائية:",
      fr: "Combinez la livraison et le marketing pour une expérience exceptionnelle :",
    },
    content: {
      en: "Explore a wide range of products on our electronic platform. Our effective marketing strategies highlight your offers and products innovatively, making it easy for you to browse and choose what suits your needs.",
      ar: "استكشف مجموعة واسعة من المنتجات على منصتنا الإلكترونية. تسويق فعّال يسلط الضوء على عروضك ومنتجاتك بشكل مبتكر. تصفح واختر ما يناسب احتياجاتك بسهولة.",
      fr: "Explorez une large gamme de produits sur notre plateforme électronique. Un marketing efficace qui met en valeur vos offres et vos produits de manière innovante. Parcourez et choisissez facilement ce qui convient à vos besoins.",
    },
    animation: "30%",
  },
];

export default featuresData;
