import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const PhoneIcon = ({ phone }) => {
  const handleButtonClick = () => {
    window.location.href = `tel:${phone}`;
  };

  return (
    <>
      <Link onClick={handleButtonClick} target="_blank" className="wpr-pc-btn">
        <div className="wpr-pc-content">
          <span className="wpr-pc-btn-icon">
            <FontAwesomeIcon icon={faPhone} fontSize={20} color={"#fff"} />
          </span>
        </div>
      </Link>
    </>
  );
};

export default PhoneIcon;
