import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import transEn from "../locale/lang-en.json";
import transFr from "../locale/lang-fr.json";
import transAr from "../locale/lang-ar.json";

const resources = {
  en: {
    translation: transEn,
  },
  fr: {
    translation: transFr,
  },
  ar: {
    translation: transAr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
