import srcImage1 from "../assets/images/services/4aa9ada6-deilbox-icon-01.png";
import srcImage2 from "../assets/images/services/c556de9a-group-2068.png";
import srcImage3 from "../assets/images/services/support.png";
import srcImage4 from "../assets/images/services/e18282b5-deilbox-icon-02.png";
import srcImage6 from "../assets/images/services/personal.png";
import srcImage8 from "../assets/images/services/box.png";

export const servicesCards = [
  {
    id: 1,
    title: { en: "Fast Delivery", ar: "توصيل سريع", fr: "Livraison rapide" },
    subtitle: {
      en: "Quick delivery with real-time tracking, updates, and notifications",
      ar: "توصيل سريع مع تتبع في الوقت الحقيقي والتحديثات والإشعارات",
      fr: "Livraison rapide avec suivi en temps réel, mises à jour et notifications",
    },
    srcImage: srcImage1,
  },
  {
    id: 2,
    title: {
      en: "Secure Packaging",
      ar: "تغليف آمن",
      fr: "Emballage sécurisé",
    },
    subtitle: {
      en: "Ensuring your items arrive safely with our top-notch packaging",
      ar: "ضمان وصول العناصر الخاصة بك بأمان مع تغليفنا عالي الجودة",
      fr: "Assurer l'arrivée sûre de vos articles avec notre emballage de première qualité",
    },
    srcImage: srcImage2,
  },
  {
    id: 3,
    title: {
      en: "24/7 Support",
      ar: "دعم على مدار الساعة",
      fr: "Support 24/7",
    },
    subtitle: {
      en: "Customer support available around the clock to assist you",
      ar: "الدعم الفني متاح على مدار الساعة لمساعدتك",
      fr: "Assistance client disponible 24h/24 et 7j/7 pour vous aider",
    },
    srcImage: srcImage3,
  },
  // New features
  {
    id: 4,
    title: {
      en: "Express Shipping",
      ar: "شحن سريع",
      fr: "Expédition express",
    },
    subtitle: {
      en: "Get your items delivered faster with our express shipping services",
      ar: "احصل على توصيل سريع لعناصرك مع خدمات الشحن السريع لدينا",
      fr: "Faites livrer vos articles plus rapidement avec nos services d'expédition express",
    },
    srcImage: srcImage4,
  },
  {
    id: 6,
    title: {
      en: "Personalized Service",
      ar: "خدمة شخصية",
      fr: "Service personnalisé",
    },
    subtitle: {
      en: "Tailored services to meet your specific needs and preferences",
      ar: "خدمات مصممة لتلبية احتياجاتك وتفضيلاتك الخاصة",
      fr: "Services sur mesure pour répondre à vos besoins et préférences spécifiques",
    },
    srcImage: srcImage6,
  },
  {
    id: 8,
    title: {
      en: "Quality Assurance",
      ar: "ضمان الجودة",
      fr: "Assurance qualité",
    },
    subtitle: {
      en: "Assured quality products and services for customer satisfaction",
      ar: "منتجات وخدمات ذات جودة مضمونة لرضا العملاء",
      fr: "Produits et services de qualité assurée pour la satisfaction du client",
    },
    srcImage: srcImage8,
  },
];
