import React from "react";

const MiniLoader = () => {
  return (
    <div className="miniLoader">
      <span className="loader"></span>
    </div>
  );
};

export default MiniLoader;
