import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { getAllBlogs, getBlogByID } from "../api/functions/posts";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const SingleBlog = () => {
  const { t, i18n } = useTranslation();
  const { blogs: blogsList, setBlogs: setBlogsContext } =
    useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const [tags, setTags] = useState([]);

  const getBlogsData = async () => {
    const res = await getAllBlogs();
    if (res.status === 200) {
      setBlogsContext(res.data.posts);
    }
  };

  const getBlog = async (id) => {
    try {
      const blogRes = await getBlogByID(id);
      if (blogRes.status === 200) {
        setBlog(blogRes.data.blog);
        setTags(JSON.parse(blog.tags));
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    getBlogsData();

    console.log(" => ", blogsList);

    getBlog(id);
  }, [id, blogsList]);

  const renderContent = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            i18n.language === "en"
              ? blog.content?.en
              : i18n.language === "fr"
              ? blog.content?.fr
              : blog.content?.ar,
        }}
      />
    );
  };

  const handleClickBlog = (id) => {
    navigate(`/blog/${id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="singleBlog default-padding">
        <div className="container-fluid">
          <article>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <article>
                  <div className=" py-5">
                    <h2 className=" blog-title">
                      {i18n.language === "en"
                        ? blog.title?.en
                        : i18n.language === "fr"
                        ? blog.title?.fr
                        : blog.title?.ar}
                    </h2>
                    <div className="cover">
                      <img
                        className="card-image"
                        src={`${IMAGE_URL}/${blog.photo}`}
                        alt=""
                      />
                    </div>
                    <div className="tags">
                      {tags &&
                        tags?.map((tag, index) => (
                          <span className="tag" key={index}>
                            {tag.value}
                          </span>
                        ))}
                    </div>
                    <div className="">{renderContent()}</div>
                  </div>
                </article>
              </div>
              <div className="col-lg-4 col-md-12 mt-5">
                <h2>{t(`blog.recommended`)}</h2>
                {blogsList &&
                  blogsList.slice(0, 3).map((blog) => (
                    <div
                      onClick={() => handleClickBlog(blog.id)}
                      className="card card-styling d-flex flex-row mb-3"
                      key={blog.id}
                    >
                      <div className="card-header">
                        <img
                          className="card-img-top"
                          src={`${IMAGE_URL}/${blog.photo}`}
                          alt="Card image cap"
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title mb-3">
                          {i18n.language === "en"
                            ? blog.title.en
                            : i18n.language === "fr"
                            ? blog.title.fr
                            : blog.title.ar}
                        </h5>
                        <Link
                          to={`/blog/${blog.id}`}
                          className="btn bg-primarycolor"
                        >
                          {t(`blog.readmore`)}
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default SingleBlog;
