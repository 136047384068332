import React from "react";
import Banner from "../components/banner";
import { useTranslation } from "react-i18next";
import ServiceCard from "../components/ServiceCard";
import { servicesCards } from "../data/servicesCards";
import Breadcrumb from "../components/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import aboutImage from "../assets/images/about/22fefaf0-4.png";

const Services = () => {
  const { t, i18n } = useTranslation();

  const handleButtonClick = () => {
    window.location.href = "tel:+212688887905";
  };

  const mailTo = () => {
    window.location.href = "mailto:livrailiya1@gmail.com";
  };

  return (
    <>
      <Breadcrumb page={t(`banner.services`)} />

      <div id="about" className="default-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <h2 className="title">{t("about.title")}</h2>
                <p className="subtitle">{t("about.content")}</p>
              </div>
              <ul>
                {t("about.list")
                  .split(" - ")
                  .map((item, index) => (
                    <li key={index} className="mb-2">
                      <div className="icon">
                        <FontAwesomeIcon icon={faCircleCheck} fontSize={20} />
                      </div>
                      {item}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="col-md-6">
              <figure className="image">
                <img src={aboutImage} alt="" />
              </figure>
            </div>
          </div>
        </div>
        <div className="letstart">
          <div className="container">
            <div className="row mt-4">
              <div className="letsstart">
                <h2 className="title text-center">{t(`about.start_today`)}</h2>
                <p className="letsstart-subtitle text-center">
                  {t(`about.contact_us`)}
                </p>
                <div className="contacts-btns">
                  <strong onClick={handleButtonClick}>
                    {" "}
                    {t(`about.phone_number`)}
                  </strong>
                  <br />
                  <strong onClick={mailTo}> {t(`about.email`)}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="services default-padding">
        <div className="container">
          <div className="row">
            {servicesCards.map((card, index) => (
              <ServiceCard
                key={card.id}
                title={card.title}
                subtitle={card.subtitle}
                image={card.srcImage}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
