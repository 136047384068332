import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faAddressBook,
  faBlog,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Billboard from "../../assets/images/billboard/Cream Black 3D Delivery Food Instagram Story.png";
import logo from "../../assets/images/logo/logo.png";
import { AppContext } from "../../context/AppContext";

const Sidebar = () => {
  const { t } = useTranslation();
  const pages = ["Home", "Services", "Contacts", "Blogs"];
  const icons = [faHome, faBriefcase, faAddressBook, faBlog];
  const { isShowSidebar, setIsShowSidebar } = useContext(AppContext);

  const closeSidebar = () => {
    let sidebar = document.getElementById("sidebar");
    if (isShowSidebar) {
      sidebar.classList.remove("active");
      setIsShowSidebar(!isShowSidebar);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div id="sidebar" className={`${isShowSidebar ? "active" : ""}`}>
        <div className="close-icon" onClick={closeSidebar}>
          <FontAwesomeIcon icon={faXmark} fontSize={70} color={"#e84c4f"} />
        </div>
        <div className="brand-logo">
          <img src={logo} alt="" />
        </div>
        <ul>
          {pages.map((page, index) => (
            <li key={index}>
              <NavLink
                to={
                  page.toLowerCase() === "home" ? "/" : `/${page.toLowerCase()}`
                }
                onClick={closeSidebar}
                className={"nav-item"}
                activeClassName="active"
              >
                <FontAwesomeIcon icon={icons[index]} />{" "}
                <span>{t(`navbar.${page.toLowerCase()}`)}</span>
              </NavLink>
            </li>
          ))}
        </ul>
        <img src={Billboard} alt="" />
      </div>
    </>
  );
};

export default Sidebar;
