import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const Overlay = () => {
  const { isShowSidebar, setIsShowSidebar } = useContext(AppContext);

  const handleOverlay = () => {
    setIsShowSidebar(!isShowSidebar);
  };
  return (
    <>
      <div
        className={`overlay-background1 ${isShowSidebar ? "active" : ""}`}
        onClick={handleOverlay}
      ></div>
      <div
        className={`overlay-background2 ${isShowSidebar ? "active" : ""}`}
        onClick={handleOverlay}
      ></div>
      <div
        className={`overlay-background3 ${isShowSidebar ? "active" : ""}`}
        onClick={handleOverlay}
      ></div>
      <div
        className={`overlay-background4 ${isShowSidebar ? "active" : ""}`}
        onClick={handleOverlay}
      ></div>
    </>
  );
};

export default Overlay;
