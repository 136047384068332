import React, { useContext, useEffect, useRef } from "react";
import $ from "jquery";
import "magnific-popup";
import "magnific-popup/dist/magnific-popup.css";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { servicesCards } from "../data/servicesCards";
import ServiceCard from "../components/ServiceCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { featuresData } from "../data/featuresData";

import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { getAllBlogs } from "../api/functions/posts";
import ContactForm from "../components/ContactForm";

import herobike from "../assets/images/hero/herobike-bg.png";

gsap.registerPlugin(ScrollTrigger);

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Home = ({ contacts }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { blogs: blogsList, setBlogs: setBlogsContext } =
    useContext(AppContext);

  const getBlogsData = async () => {
    const res = await getAllBlogs();
    if (res.status === 200) {
      setBlogsContext(res.data.posts);
    }
  };

  useEffect(() => {
    getBlogsData();
  }, []);

  // ----------------Hero
  const heroRef = useRef(null);
  const herobikeRef = useRef(null);
  const signatureRef = useRef(null);
  // ----------------Services Card
  const cardRefs = useRef(Array(servicesCards.length).fill(null));
  // ----------------Feature
  const featuresRef = useRef(null);
  const featureBuyerRef = useRef(null);
  const featureBuyer2Ref = useRef(null);
  const featureBuyer3Ref = useRef(null);
  // ----------------blog
  const blogsRef = useRef(null);

  useEffect(() => {
    // ------------------------------ Hero ------------------------------
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.current,
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
    });

    tl.from(signatureRef.current, { opacity: 0, y: 30, duration: 1 });
    tl.to(signatureRef.current, { opacity: 1, y: 0, duration: 2 });
    tl.from(herobikeRef.current, {
      x: "60%",
      duration: 100,
      ease: "power2.out",
    });
    tl.to(herobikeRef.current, { x: "0", duration: 100, ease: "power2.out" });

    // ------------------------------ Service ------------------------------
    const cardTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".service-card",
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
    });

    cardTl.from(cardRefs.current, { opacity: 0, y: 50, stagger: 0.2 });
    cardTl.to(cardRefs.current, { opacity: 1, y: 0, stagger: 0.2 });

    // ------------------------------ Features ------------------------------
    const tlFea = gsap.timeline({
      scrollTrigger: {
        trigger: featuresRef.current,
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
    });

    tlFea.to(featureBuyerRef.current, {
      x: "5%",
      duration: 4,
      ease: "power2.out",
    });
    tlFea.to(featureBuyer2Ref.current, {
      x: "-20%",
      duration: 4,
      ease: "power2.out",
    });

    // ------------------------------ Blogs ------------------------------
    const blogTl = gsap.timeline({
      scrollTrigger: {
        trigger: blogsRef.current,
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
    });

    blogsList &&
      blogsList.forEach((card, index) => {
        blogTl.from(`.blog-card-${index}`, { opacity: 0, y: 50, stagger: 0.2 });
      });
  }, []);

  useEffect(() => {
    $(".play-btn").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }, []);

  const handleButtonClick = () => {
    window.location.href = `tel:${contacts.phone}`;
  };

  const mailTo = () => {
    window.location.href = `mailto:${contacts.email}`;
  };

  const OrderNumber = useRef(null);
  const numberAnimationsRefs = useRef([]);

  useEffect(() => {
    const statisticTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".lastVidew",
        start: "top bottom", // Trigger animation when the top of lastVidew enters the bottom of the viewport
        end: "bottom bottom",
        once: true, // Only trigger once
        onEnter: () => {
          // Animate the numbers
          numberAnimationsRefs.current.forEach((ref, index) => {
            gsap.to(ref, {
              duration: 3,
              innerHTML: index === 0 ? 500 : index === 1 ? 260 : 1000,
              roundProps: "innerHTML",
            });
          });
        },
      },
    });
  }, []);

  return (
    <>
      <div id="hero" ref={heroRef}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6" data-aos="fade-left">
              <div className="main-content">


                <h1>{t(`hero.title`)}</h1>
                <p>{t(`hero.subtitle`)}</p>
                <div className="buttons position-relative">
                  <div className="btn-custom" onClick={handleButtonClick}>
                    <button>{t(`hero.request-btn`)}</button>
                    <div className="border"></div>
                    <div className="border"></div>
                    <div className="border"></div>
                    <div className="border"></div>
                  </div>
                  <a
                    className="popup-youtube play-btn video-play-button"
                    href={"https://www.youtube.com/watch?v=rADdB0kn1gA"}
                  >
                    <FontAwesomeIcon icon={faPlay} color="#fff" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <figure className="herobike">
                <img src={herobike} ref={herobikeRef} alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div
        id="features"
        className="features default-padding"
        dir={"ltr"}
        ref={featuresRef}
      >
        <div className="container">
          <div className="mb-5 pb-5">
            <h2 className="title text-center">{t(`features.title`)}</h2>
          </div>
          {featuresData.map((feature, index) => (
            <div
              className={`row align-items-center mb-5  ${index === 1 ? "flex-row-reverse" : ""
                }`}
              key={index}
            >
              <div className={`col-lg-6 col-md-12  ${feature.imageClass}`}>
                <img
                  className={`featureBuyer${index + 1}`}
                  src={feature.image}
                  alt=""
                  ref={
                    index === 0
                      ? featureBuyerRef
                      : index === 1
                        ? featureBuyer2Ref
                        : index === 2
                          ? featureBuyer3Ref
                          : null
                  }
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <h4 className="main-heading">
                  {i18n.language === "en"
                    ? feature.title.en
                    : i18n.language === "ar"
                      ? feature.title.ar
                      : feature.title.fr}
                </h4>
                <p className="subtitle">
                  {i18n.language === "en"
                    ? feature.content.en
                    : i18n.language === "ar"
                      ? feature.content.ar
                      : feature.content.fr}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div id="services" className="services default-padding">
        <div className="container">
          <div className="row">
            {servicesCards.slice(0, 3).map((card, index) => (
              <ServiceCard
                key={card.id}
                title={card.title}
                subtitle={card.subtitle}
                image={card.srcImage}
                ref={(el) => (cardRefs.current[index] = el)}
              />
            ))}
          </div>
        </div>
      </div>

      <section className="lastVidew" ref={OrderNumber}>
        <div className="container">
          <div className="row">
            {/* Number of site views */}
            <div className="col-lg-4 col-md-6 col-sm-12 py">
              <div className="RatingBox">
                <div className="py-52px mx-auto">
                  <div className="text-center">
                    <span
                      className="NumberAnimation"
                      ref={(el) => (numberAnimationsRefs.current[0] = el)}
                    >
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p className="text-center sub">Site Views</p>
                </div>
              </div>
            </div>
            {/* Number of orders */}
            <div className="col-lg-4 col-md-6 col-sm-12 py">
              <div className="RatingBox">
                <div className="py-52px mx-auto">
                  <div className="text-center">
                    <span
                      className="NumberAnimation"
                      ref={(el) => (numberAnimationsRefs.current[1] = el)}
                    >
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p className="text-center sub">Orders</p>
                </div>
              </div>
            </div>
            {/* Third statistic */}
            <div className="col-lg-4 col-md-6 col-sm-12 py">
              <div className="RatingBox">
                <div className="py-52px mx-auto">
                  <div className="text-center">
                    <span
                      className="NumberAnimation"
                      ref={(el) => (numberAnimationsRefs.current[2] = el)}
                    >
                      0
                    </span>
                    <span>+</span>
                  </div>
                  <p className="text-center sub">Third Statistic</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="contacts" className="contact default-padding">
        <div className="container">
          <div className="row">
            <div className="mb-5">
              <h2 className="title text-center">{t(`contact.title`)}</h2>
              <p className="subtitle text-center">{t(`contact.subtitle`)}</p>
            </div>
            <div className="col-md-6" data-aos="fade-right">
              <ul className="icon-list-items mb-4"></ul>
              <ul className="">
                <h3 className="main-heading mb-4">
                  {t(`contact.main-heading`)}
                </h3>
                <p className="subtitle">{t(`contact.sub-heading`)}</p>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <span>livrailiya1@gmail.com</span>
                </li>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <span>0688887905</span>
                </li>
                <li>
                  <div className="icon">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <span>{t(`contact.adress`)}</span>
                </li>
              </ul>
            </div>
            <div className="col-md-6" data-aos="fade-left">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      <div id="blogs" className="blogs default-padding" ref={blogsRef}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between mb-5">
            <h2 className="title">{t(`blog.title`)}</h2>

            <div className="btn-custom">
              <button
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                {t(`blog.all-btn`)}
              </button>
              <div className="border"></div>
              <div className="border"></div>
              <div className="border"></div>
              <div className="border"></div>
            </div>
          </div>
          <div className="row">
            {blogsList &&
              blogsList.slice(0, 3).map((blog) => (
                <div
                  className={`col-md-4 mb-4 blog-card-${blog._id}`}
                  key={blog._id}
                  onClick={() => {
                    navigate(`blog/${blog._id}`);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <div className="card">
                    <img
                      src={`${IMAGE_URL}/${blog.photo}`}
                      alt={blog?.title?.en}
                    />
                    <div className="card-body">
                      <h5 className="card-title">
                        {i18n.language === "en"
                          ? blog.title.en
                          : i18n.language === "ar"
                            ? blog.title.ar
                            : blog.title.fr}
                      </h5>
                      <p className="card-text">
                        {/* {i18n.language === "en"
                          ? blog.description.en
                          : i18n.language === "ar"
                          ? blog.description.ar
                          : blog.description.fr} */}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
