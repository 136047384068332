import React, { useEffect, useState } from "react";
import waICON from "../../assets/images/whatsapp.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WhatsappICON = ({ phone }) => {
  const { t } = useTranslation();

  return (
    <div id="wa" className="position-relative">
      <Link to={phone} target="_blank">
        <figure className={`wa-icon`}>
          <img src={waICON} alt={"Sofa"} />
        </figure>
      </Link>
    </div>
  );
};

export default WhatsappICON;
